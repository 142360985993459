<form [formGroup]="authenticationForm">
  @if (isGlobalLoading) {
    <div class="social-login-loader">
      <div class="spinner-container">
        <ion-spinner name="circular" />
      </div>
    </div>
  }

  @if ((authenticationStepService.currentStep$ | async)?.step === authenticationSteps.EMAIL) {
    <div [@fadeIn]>
      <ion-input
        #emailInput
        (ionInput)="showError = false"
        (keyup.enter)="checkEmail()"
        formControlName="email"
        data-cy="email-input"
        type="email"
        class="white"
        [class.white]="authenticationForm.get('email')?.value"
        [class.error]="showError"
        [placeholder]="'PWA_LoginOrCreateAccount_field_enterEmail' | translate">
        @if (authenticationForm.get('email')?.value) {
          <img src="assets/icon/blue-close.svg" slot="end" (click)="clearEmail()" />
        }
      </ion-input>

      @if (showError) {
        <app-input-error [error]="customError || 'PWA_Login_field_email_error_invalidEmail' | translate" />
      }
      <app-button data-cy="email-next-btn" (clicked)="checkEmail()" [isLoading]="isButtonLoading" [label]="'PWA_mainActions_CTA_continue' | translate" [size]="'normal'" />

      <div class="login-provider-or">
        <div class="line"></div>
        <div class="or">{{ 'PWA_LoginOrCreateAccount_divider_or' | translate }}</div>
        <div class="line"></div>
      </div>

      <div class="social-login-container">
        <ion-button class="social-login-button" (click)="loginWithFacebook()">
          <div class="content">
            <img src="assets/icon/facebook.svg" alt="" />
            {{ 'PWA_LoginOrCreateAccount_socialOption_facebook' | translate }}
          </div>
        </ion-button>

        @if (Capacitor.getPlatform() !== 'android') {
          <ion-button class="social-login-button" (click)="loginWithApple()">
            <div class="content">
              <img src="assets/icon/apple.svg" alt="" />
              {{ 'PWA_LoginOrCreateAccount_socialOption_apple' | translate }}
            </div>
          </ion-button>
        }

        <ion-button class="social-login-button" (click)="loginWithGoogle()">
          <div class="content">
            <img src="assets/icon/google.svg" alt="" />
            {{ 'PWA_LoginOrCreateAccount_socialOption_google' | translate }}
          </div>
        </ion-button>
      </div>
    </div>
  } @else if ((authenticationStepService.currentStep$ | async)?.step === authenticationSteps.FORGOT_PASSWORD) {
    <div [@fadeIn]>
      <div class="subtitle">
        {{ 'PWA_forgotPassword_body' | translate }}
      </div>

      <ion-input
        (ionInput)="showError = false"
        (keyup.enter)="resetPassword()"
        formControlName="email"
        data-cy="forgot-password-input"
        type="email"
        class="white"
        [class.white]="authenticationForm.get('email')?.value"
        [class.error]="showError"
        [placeholder]="'PWA_forgotPassword_field_enterYourEmail' | translate">
      </ion-input>

      @if (showError) {
        @if (authenticationForm.get('email')?.invalid) {
          <app-input-error [error]="'PWA_Login_field_email_error_emptyField' | translate" />
        } @else {
          <app-input-error data-cy="forgot-password-reset-error" [error]="customError || 'PWA_forgotPassword_field_error_noAccountAssociated' | translate" />
        }
      }

      <app-button
        data-cy="forgot-password-reset-btn"
        (clicked)="resetPassword()"
        [isLoading]="isButtonLoading"
        [label]="'PWA_resetPassword_CTA_Submit' | translate"
        [size]="'normal'" />
    </div>
  } @else if ((authenticationStepService.currentStep$ | async)?.step === authenticationSteps.FORGOT_PASSSWORD_CONFIRM) {
    <div [@fadeIn]>
      <div class="subtitle">{{ 'PWA_forgotPassword_emailSent_confirmationMessage' | translate }}</div>

      <app-button
        data-cy="forgot-password-confirm-btn"
        (clicked)="startReading()"
        [isLoading]="isButtonLoading"
        [label]="'PWA_mainActions_CTA_continue' | translate"
        [size]="'normal'" />
    </div>
  } @else if ((authenticationStepService.currentStep$ | async)?.step === authenticationSteps.LOGIN) {
    <div [@fadeIn]>
      <app-authentication-password
        (passwordConfirmed)="login()"
        [showError]="showError"
        data-cy="email-password"
        [placeholder]="'PWA_Login_field_enterPassword' | translate"
        [showValidation]="false"
        [value]="authenticationForm.get('signinPassword')?.value || ''"
        (valueChanged)="authenticationForm.get('signinPassword')?.setValue($event); showError = false">
      </app-authentication-password>

      @if (showError) {
        @if (authenticationForm.get('signinPassword')?.invalid) {
          <app-input-error data-cy="login-empty-field" [error]="'PWA_Login_field_enterPassword_error_emptyField' | translate" />
        } @else {
          <app-input-error data-cy="login-custom-error" [error]="customError || 'PWA_Login_field_enterPassword_error_incorrect' | translate" />
        }
      }

      <div data-cy="forgot-password-btn" class="forgot-password" (click)="goToStep(authenticationSteps.FORGOT_PASSWORD)">
        <div>{{ 'PWA_Login_CTA_link_forgotPassword' | translate }}</div>
        <div class="forgot-password-img arabic-transform"></div>
      </div>

      <app-button data-cy="login-password-btn" (clicked)="login()" [isLoading]="isButtonLoading" [label]="'PWA_mainActions_CTA_continue' | translate" [size]="'normal'" />
    </div>
  } @else if ((authenticationStepService.currentStep$ | async)?.step === authenticationSteps.CREATE_ACCOUNT) {
    <div [@fadeIn]>
      <app-authentication-password
        (passwordConfirmed)="createAccount()"
        data-cy="create-account-password-input"
        [placeholder]="'PWA_createAccount_field_createPassword' | translate"
        [showError]="showError"
        [value]="authenticationForm.get('signupPassword')?.value || ''"
        (valueChanged)="authenticationForm.get('signupPassword')?.setValue($event); showError = false">
      </app-authentication-password>

      <div class="create-account-checkboxes">
        <div class="checkbox-block-container">
          <ion-checkbox
            [class.checkbox-error]="showError && authenticationForm.get('terms')?.invalid"
            alignment="start"
            data-cy="terms-checkbox"
            formControlName="terms"
            labelPlacement="end">
          </ion-checkbox>
          <div class="wrap" [innerHTML]="'PWA_Login_check_TC_normal' | translate"></div>
        </div>
        @if (displayCoppaCheckBox) {
          <div class="checkbox-block-container">
            <ion-checkbox
              [class.checkbox-error]="showError && authenticationForm.get('coppa')?.invalid"
              alignment="start"
              data-cy="coppa-checkbox"
              formControlName="coppa"
              labelPlacement="end">
            </ion-checkbox>
            <div class="wrap">{{ 'PWA_createAccount_check_coppa' | translate }}</div>
          </div>
        }

        @if (showError && authenticationForm.get('terms')?.invalid) {
          <app-input-error data-cy="create-account-error" [error]="customError || 'PWA_createAccount_check_TC_normal_error_accept' | translate" />
        } @else if (showError && authenticationForm.get('coppa')?.invalid && displayCoppaCheckBox) {
          <app-input-error [error]="'PWA_createAccount_check_COPPA_normal_error_accept' | translate" />
        } @else if (customError && showError) {
          <app-input-error [error]="customError" />
        }
      </div>

      <app-button
        data-cy="create-account-btn"
        (clicked)="createAccount()"
        [isLoading]="isButtonLoading"
        [label]="'PWA_createAccount_CTA_createAccount' | translate"
        [size]="'normal'" />
    </div>
  } @else if ((authenticationStepService.currentStep$ | async)?.step === authenticationSteps.ACCOUNT_CREATED) {
    <div [@fadeIn]>
      <div class="subtitle">{{ 'PWA_createAccount_confirmation_body' | translate }}</div>

      <app-button data-cy="create-profile-btn" (click)="createProfile()" [label]="'PWA_createAccount_confirmedEmail_CTA_createProfile' | translate" [size]="'normal'" />

      <div class="footer">{{ 'PWA_createAccount_confirmation_reminder_body' | translate }}</div>
    </div>
  } @else if ((authenticationStepService.currentStep$ | async)?.step === authenticationSteps.RESET_PASSWORD) {
    <div [@fadeIn]>
      <div class="subtitle">{{ 'PWA_resetPassword_body' | translate }}</div>

      <app-authentication-password
        data-cy="reset-password-input"
        (passwordConfirmed)="saveNewPassword()"
        [placeholder]="'PWA_createAccount_field_createPassword' | translate"
        [showError]="showError"
        [value]="authenticationForm.get('newPassword')?.value || ''"
        (valueChanged)="authenticationForm.get('newPassword')?.setValue($event)">
      </app-authentication-password>

      @if (showError) {
        <app-input-error data-cy="reset-password-error" [error]="customError || 'PWA_forgotPassword_field_error_emptyField' | translate" />
      }

      <div class="reset-password-button">
        <app-button data-cy="reset-password-btn" (click)="saveNewPassword()" [label]="'PWA_resetPassword_CTA_Submit' | translate" [size]="'normal'" />
      </div>
    </div>
  } @else if ((authenticationStepService.currentStep$ | async)?.step === authenticationSteps.RESET_PASSWORD_CONFIRM) {
    <div [@fadeIn]>
      <div class="password-reset-success">
        {{ 'PWA_resetPassword_success_body' | translate }}
      </div>

      <div class="start-reading-button">
        <app-button data-cy="password-change-success-btn" (click)="goToStep(authenticationSteps.EMAIL)" [label]="'PWA_Login_CTA_login' | translate" [size]="'normal'" />
      </div>
    </div>
  } @else if ((authenticationStepService.currentStep$ | async)?.step === authenticationSteps.EMAIL_VALIDATED) {
    <div [@fadeIn]>
      <div class="welcome-booksmart">{{ 'PWA_createAccount_confirmationEmail_subject' | translate }}</div>

      <div class="subtitle">{{ 'PWA_createAccount_confirmedEmail_body' | translate }}</div>

      <app-button data-cy="email-validated-btn" (click)="goToProfileSelection()" [label]="'PWA_createAccount_confirmationEmail_CTA_startReading' | translate" [size]="'normal'" />
    </div>
  } @else if ((authenticationStepService.currentStep$ | async)?.step === authenticationSteps.EMAIL_NOT_VALIDATED) {
    <div [@fadeIn]>
      <div class="subtitle">{{ 'PWA_Login_pendingConfirmation_header' | translate }}</div>
      <app-button
        data-cy="email-not-validated-get-started-btn"
        [expand]="(appService.isMobile$ | async) ? 'block' : 'default'"
        [label]="'PWA_legacyWelcome_CTA_getStarted' | translate"
        (clicked)="goToProfileSelection()" />

      <div class="resend-email" [class.is-loading]="isButtonLoading">
        <div data-cy="resend-email-btn" class="resend-email-title" (click)="resendEmail()">
          {{ 'PWA_Login_pendingConfirmation_CTA_link_resendEmail' | translate }}
        </div>
        @if (isButtonLoading) {
          <ion-spinner name="circular"></ion-spinner>
        } @else {
          <img src="assets/icon/right-arrow-bold.svg" class="arabic-transform" alt="" />
        }
      </div>
    </div>
  }
</form>
